.inpd {
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1rem;
  gap: 0.3rem;
  padding: 0 2rem;
}

.boxnum {
  // grid-column: 1 / 4;
  // grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.wrap {
  display: flex;
}

.text {
  color: #181d58;
  font-weight: bold;
  // font-style: italic;
  // font-family: Georgia, serif;
  // font-family: "AMEDA REGULA", sans-serif;
  // font-family: "Arsenal", sans-serif;
  font-family: "Arsenal", sans-serif;

  margin: 0.5rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

.inp {
  margin-top: 0.2rem;
  border: none;
  border-radius: 4px;
  padding: 0.4rem;
  height: 2rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  // font-family: inherit;
  font-family: "Arsenal", sans-serif;
}

.inparea {
  margin-top: 0.2rem;
  border: none;
  border-radius: 4px;
  // height: 16rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  // font-family: inherit;
  font-family: "Arsenal", sans-serif;
}

.check {
  margin-top: 10px; // Регулируйте отступ вверху по вашему усмотрению
  align-self: start;
  label {
    display: flex;
    align-items: center;
    font-size: 16px; // Регулируйте размер шрифта по вашему усмотрению
    color: #333; // Цвет текста
  }
}
