.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menuItemWrapper {
  position: relative;
  display: inline-block;
}

.subMenu {
  display: none;
  position: absolute;
  left: 0;
  // margin-top: 1rem;
  // top: 100%;
  width: 500px;
  height: 200px;
  background-color: rgb(243 243 243);
  border: 1px solid #ccc;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  // background-image: linear-gradient(to bottom, #ffffff, #f4f4f4);
  z-index: 1;
}

.menuItemWrapper:hover .subMenu {
  display: block;
}

// .menuItemSub {
//   z-index: 200;
// }

.elementsMenu {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-transform: uppercase;
}

.downElementsMenu {
  list-style: none;
  padding: 1rem;
}

.promo {
  text-decoration: none;
  font-size: 18px;
  padding: 0 0.5rem;
  color: #311313;
  transition: transform 0.2s ease-in-out; // Добавляем плавную анимацию

  &:hover {
    transform: scale(1.3); // Используем transform вместо scale
  }
}

.menuItem {
  padding: 0 0.5rem;
}

.menuItem a {
  text-decoration: none;
  font-size: 18px;

  font-family: "Arsenal", sans-serif;

  color: #311313;
  font-weight: 400;
}

.menuItemSub a {
  text-decoration: none;
  font-size: 20px;
  color: #311313;
}
.menuItemSub a:hover {
  color: rgb(160, 160, 160);
}
.menuItem a:hover {
  color: rgb(160, 160, 160);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media (max-width: 1199px) {
  .menu {
    display: none;
  }
}
