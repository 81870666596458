.breadcrumb {
  display: flex;
  align-items: center;
}

.crumb {
  text-decoration: none;
  color: #3e413e;
  //   font-weight: bold;
}

.separator {
  margin: 0 5px;
  color: #3e413e;

  font-size: small;
}
