.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // display: none;
  opacity: 0;
  transition: all 0.8s;

  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.modal.active {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1rem;    //включаем паддинг и есть проблема с выравниванием
  border-radius: 5px;
  background-color: white;
  max-width: 1200px;
  object-fit: cover;
  z-index: 3;

  // img {
  //   min-height: 100%;
  //   min-width: 100%;
  // }
}

@media (max-width: 1199px) {
  .modal.active {
    margin-top: 2rem;
    align-items: flex-start;
  }
}
