.image {
  width: 700px;
  height: 500px;
  margin: 7px;
}
@media (max-width: 768px) {
  .image {
    width: 100%; /* Установите ширину на 100% для мобильных устройств */
    height: auto; /* Сохраните соотношение сторон */
    margin: 7px;
  }
}
