.wrapper {
  position: relative;
  display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // justify-content: center;
  margin: auto;
  align-items: center;
  font-size: 20px;
  gap: 1rem;
  // max-width: 100%;
  // color: white;
  font-style: italic;
  // max-height: 400px;
  // max-width: 700px;
  // margin: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.dostavka {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: black;
  // display: flex;
  gap: 0.3rem;
}

.btns {
  display: flex;
  gap: 1rem;
}

// .wrapper::before {
//   content: "";
//   position: absolute;
//   top: -5%;
//   left: 13%;
//   right: 13%;
//   bottom: -10%;
//   background-color: rgba(78, 77, 145, 0.93);
//   box-shadow: -9px 10px 10px rgba(0, 0, 0, 0.5);
//   z-index: -1;
// }

.form {
  display: block;
  padding: 1rem;

  input,
  textarea {
    // box-shadow: 5px -6px 8px 1px #6e6f6e;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    margin: 1rem;
  }
  input,
  textarea {
    border: 1px solid #ccc;
  }
  // textarea {
  //   max-width: 90%;
  //   height: 30%;
  // }
  input::placeholder,
  textarea::placeholder {
    color: #525252; /* Цвет текста placeholder */
    font-style: italic; /* Наклонный стиль текста placeholder (если нужно) */
    font-size: 1rem;
    /* Другие стили, такие как размер шрифта, отступы и др. */
  }
  button {
    padding: 10px 15px;
    background-color: #0f600d;
    color: #fff;
    width: 60%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    /* Другие стили, такие как отступы, тень и др. */

    &:hover {
      background-color: #0e600da1; /* Измените цвет при наведении, если нужно */
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
      /* Другие стили для отключенного состояния */
    }
  }
  // width: 50%;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
}

textarea {
  width: 90%;
  height: 30%;
  padding: 5px;
  margin-bottom: 10px;
  border: 2px solid rgba(76, 44, 105, 0.548);
  border-radius: 4px;
}

@media (max-width: 768px) {
  /* Стили для мобильных устройств */

  .dostavka {
    align-items: center;
    //  label {
    //   margin-bottom: 8px;
    // }
  }

  .form {
    max-width: 100%;
  }

  /* Добавьте другие стили для мобильных устройств по необходимости */
}

.btn {
  width: 100%;
  height: 40px;
  margin: 1rem;
  //   background-color: aqua;
}

.error {
  margin: 0;
  font-size: 15px;
  color: red;
}

// .cart {
//   width: 200px;
//   height: 200px;
// }
