.wrapper {
  margin: 1rem;
  width: 100vw;
  height: 90vh;
  display: grid;
  grid-template-columns: 2fr 0.5fr;
}

.bigimg {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 300px;
  // width: 300px;
  height: 500px;
  object-fit: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.imgWrapper {
  position: relative;
}

.btn {
  border: none;
  background: green;
  padding: 1rem;
  color: white;
  font-size: large;
  &:hover {
    background: rgba(0, 128, 0, 0.336);
    cursor: pointer;
  }
}

.btnAdd {
  @extend .btn;
  background: rgb(29, 51, 148);
  font-size: medium;
  &:hover {
    background: rgba(29, 51, 148, 0.466);
  }
}
.add {
  display: flex;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  height: 100px;
  justify-content: center;
}
.img {
  // display: flex;
  justify-content: center;
  // max-width: 100%;
  width: auto;
  height: 100px;
  // object-fit: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  // cursor: pointer;
}

.nav {
  // display: grid;
  // grid-template-columns: 1fr;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  margin: 1rem;
  align-items: start;
  flex-wrap: wrap;
  height: 90vh;
}
// .navGrid {
//   display: flex;
//   flex-wrap: wrap;
//   // grid-template-columns: 1fr;
// }

.addimg {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 110px;
  // border: 1px solid gray;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.svg {
  width: 3rem;
  stroke: darkblue;

  &:hover {
    scale: 1.3;
    transition: scale 200ms;
  }
}

.svgimage {
  width: 3rem;
  stroke: darkblue;
}

.deleteSvg {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 3rem;
  stroke: red;
  fill: rgba(93, 72, 83, 0.836);
  cursor: pointer;
  z-index: 100;

  &:hover {
    scale: 1.4;
    transition: scale 200ms;
  }
}
