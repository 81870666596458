.img {
  display: flex;
  position: relative;
  // transition: background-image 0.5s ease-in-out;
  // z-index: 1;
  width: 100%;
  height: 100%;
  // padding: 0;
  // margin: 0;
  border-radius: 7px;
  box-shadow: 4px 8px 13px -2px rgb(0 0 0 / 72%); // z-index: 200;
}
.cursor {
  margin-top: 8px;
  // top: -50px;
  position: absolute;
  // z-index: 1;
  display: flex;
  // transition: 0.5s ease;
  height: 3px;
  width: 100%;
  object-fit: cover;
}
.slide {
  transition: background-image 0.5s ease-in-out;
  // transition: transform 0.5s ease-in-out;
}
