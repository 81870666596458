.cityButton {
  background-color: #0a44c0;
  color: rgb(255, 255, 255);
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #8fc93a;
  }
}
