.wrapper {
  display: flex; /* Устанавливаем flex-контейнер */
  flex-direction: column; /* Устанавливаем направление столбцом (по вертикали) */
  align-items: center; /* Выравнивание по горизонтали по центру */
  justify-content: center; /* Выравнивание по вертикали по центру */
  //   height: 100vh; /* Высота контейнера равна высоте видимой части окна браузера */
  text-align: center; /* Выравнивание текста по центру */
}

.block {
  display: flex;
}

.left {
  align-self: center;
}

.svg {
  width: 50px;
}

.hr {
  width: 50%; /* Ширина разделителя (можете настроить по вашему усмотрению) */
  margin: 20px auto; /* Отступ сверху и снизу, а также центрирование горизонтально */
  border: none; /* Убираем стандартную границу разделителя */
  border-top: 1px solid #ccc; /* Создаем горизонтальную линию как разделитель */
}
