.blink-button {
  background-color: #3a945b; /* Цвет WhatsApp */
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  animation: blink 1s infinite;
  transition: transform 0.2s ease;
}

.blink-button:hover {
  transform: scale(1.1);
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
