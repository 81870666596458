.h1 {
  padding: 1rem;
  text-align: center;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 16rem));
  // gap: 3rem;
  // row-gap: 0px;
  align-items: start;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
  // margin-bottom: 20rem;
  @media (max-width: 768px) {
    // display: flex;
    // gap: 1rem;
    // flex-direction: column;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin: 0.5rem;
    margin-top: 0;
    // // gap: 8rem 0;
    // margin: 0;
    // padding: 0.5rem;
    margin-bottom: 1rem;
  }
  // z-index: -1;
}
/* Стили для мобильных устройств */

// .categoryTitle {
//   text-align: center;
//   align-self: center;
// }

.sortOption {
  margin-left: 1rem;
}

:global(.textAdd) {
  // background-color: red;
  color: red;
}

.placeholder {
  position: absolute;
  top: 24px;
  padding-left: 18px;
}

.editable {
  margin: 10px;
  width: 300px;
  height: 200px;
  padding: 0 8px;
  border: 1px solid black;
}

.typeimg {
  height: 200px;
  width: 255px;
  object-fit: cover;
}

.imageWrapper {
  position: relative;
  width: 100%; /* Ширина блока будет равна ширине родительского блока */
  height: 300px; /* Высота блока будет равна высоте родительского блока */
  overflow: hidden; /* Обрезаем изображение, если оно не помещается в блок */
}

.typeimg {
  width: 100%; /* Заполнение всей доступной ширины блока */
  height: 100%; /* Заполнение всей доступной высоты блока */
  object-fit: cover; /* Заполняем блок, сохраняя пропорции и обрезая по необходимости */
  filter: grayscale(100%);
  // transition: filter 0.3s;
  &:hover {
    filter: grayscale(0%);
  }
}

.gray_background {
  background-color: rgba(
    64,
    131,
    187,
    0.708
  ); /* или другой цвет серого фона, который вы предпочитаете */
  width: 100%; /* Чтобы серый фон занимал всю площадь контейнера */
  height: 100%; /* Чтобы серый фон занимал всю площадь контейнера */
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(16, 74, 30, 0.615); /* Черный полупрозрачный фон */
  text-align: center;
  padding: 10px; /* Поля вокруг текста */
  box-sizing: border-box; /* Учесть padding в ширине блока */
  color: white; /* Цвет текста */
}

.captionText {
  margin: 0; /* Убрать отступы вокруг текста */
  color: rgb(255, 255, 255);
  // font-family: "Lora", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 1.2rem;
}

.selectWrapper {
  display: inline-block;
  position: relative;
  margin: 1rem;
  width: 100%;
  max-width: 300px; /* Ограничение ширины по умолчанию */

  select {
    appearance: none;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f8f8f8;
    color: #333;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:hover {
      border-color: #808080;
    }

    &:focus {
      border-color: #00aaff;
      box-shadow: 0 0 5px rgba(0, 170, 255, 0.5);
    }
  }

  &:after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 0.8rem;
    color: #666;
  }
}
