.about {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  // font-family: Arial, sans-serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;
  color: #333;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #0076d1;
}

.description {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  color: #0076d1;
}

.principles {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 20px;
}

.principles > li {
  font-size: 16px;
  margin-bottom: 10px;
}

.principles > li strong {
  font-weight: bold;
  color: #333;
}

/* Пример, как можно стилизовать каждый из принципов более детально, например, добавляя иконки или другие стили */
/* .principles > li:nth-child(1) {
    position: relative;
  }
  
  .principles > li:nth-child(1)::before {
    content: "🌸 "; /* Пример использования иконки
    margin-right: 10px;
  } */
