.wrapper {
  color: black;
  box-shadow: 5px -6px 8px 1px #6e6f6e;
  padding: 1.5rem;
  // margin-top: 1rem;
  border: none;
  border-radius: 9px;
  background: blue;
}
.wrap {
  z-index: 1;
}
.custom {
  background-color: #216ba5;
  // margin-top: 1rem;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font: inherit;
  padding: 5px 15px;
  &:hover {
    background: rgba(71, 71, 150, 0.401);
  }
}
