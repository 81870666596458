.wrapper {
  padding: 2rem;
  border: 2px solid rgba(0, 0, 0, 0.497);
}
.tarifs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.name {
  // border: 1px solid black;
  padding: 7px;
  border-radius: 4px;
  background: green;
  color: white;
  text-align: center;
}

.elem {
  // border: 1px solid black;
  padding: 0;
  border-radius: 4px;
  background: rgb(215, 88, 77);
  color: white;
  text-align: center;
  font-size: 18px;
}

.btn {
  padding: 9px;
  border: none;
  border-radius: 4px;
  background: rgb(65, 57, 226);
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  &:hover {
    background: rgba(65, 57, 226, 0.496);
  }
}

.create {
  @extend .btn;
  background: rgb(74, 76, 85);
}

.earth {
  @extend .btn;
  background: rgb(15, 32, 110);
}

.btn:disabled,
.create:disabled {
  background: rgba(145, 149, 165, 0.5);
  cursor: not-allowed;
}

.inputs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.unit {
  border: 2px solid rgba(0, 0, 0, 0.497);
  padding: 0.7rem;
  border-radius: 4px;
  box-shadow: 5px -4px grey;
  &:hover {
    box-shadow: 1px -1px rgb(110, 53, 53);
  }
}

.inp {
  padding: 9px;
  border: 1px solid blue;
  border-radius: 4px;
  //   background: rgb(65, 57, 226);
}

.price {
  display: flex;
  gap: 3px;
}

.cities {
  border: 1px solid black;
  padding: 1rem;
}

.cityOne {
  border: 1px solid black;
  margin: 0;
  padding: 1rem;
  border-radius: 4px 4px 0 0;
}

.groupCities {
  display: flex;
}
