.wrapper {
  display: flex;
  align-items: center;
  margin: auto;
  gap: 1rem;
  text-transform: uppercase;

  justify-content: center;
  border-bottom: 1px solid white;
  padding: 0.5rem;
  // flex-direction: column;
}

.title {
  font-size: 18px;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  color: #311313;
  margin: 0;
  font-weight: bold;
}

.time {
  font-size: 20px;
  color: #fff;
}
