.wrapper {
  margin: 1rem;
}

.svg_plus {
  max-width: 200px;
  max-height: 200px;
  stroke: green;
  cursor: pointer;

  &:hover {
    scale: 1.1;
    transition: scale 200ms;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.option {
  width: 200px;
  height: 200px;
  /* Другие стили, если необходимо */
}
