// input.module.scss

.optionsSelect {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;

  p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      display: flex;

      label {
        display: flex;
        align-items: start;
      }
    }
  }
  .input {
    align-items: start;
  }
}
