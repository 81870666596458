.wrap {
  position: relative;
  // min-height: 60px;
}

.mapContainer {
  width: 40vw; /* Используем 100% ширины */
  height: 300px; /* Задаем фиксированную высоту */
}

/* Для мобильных устройств или узких экранов можно уменьшить высоту карты */
@media (max-width: 768px) {
  .mapContainer {
    width: 100vw;
    height: 200px;
  }
  .wrap {
    // min-height: 0;
  }
}
