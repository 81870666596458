.wrap {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 6px;
  }
  // flex-direction: column;
}

.ul {
  position: absolute;
  background: white;
  z-index: 200;
  top: 60px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.li {
  padding: 10px;
  z-index: 200;
  cursor: pointer;
  border: 1px solid grey;
  transition: background-color 0.3s ease;
}

.li:hover {
  z-index: 200;
  background-color: rgb(
    141,
    210,
    50
  ); /* Любой цвет, который вы хотите использовать при наведении */
}

.li.selected {
  z-index: 200;
  background-color: #e0e0e0; /* Любой цвет, который вы хотите использовать для выбранного элемента */
}

.btn {
  margin-left: 1rem;
  height: 80%;
  padding: 0.7rem;
  color: white;
  background: rgba(21, 104, 21, 0.678);
  border: none;
  border-radius: 4px;
  &:hover {
    background: rgb(21, 104, 21);
    cursor: pointer;
  }
}
