.header {
  // font-style: italic;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 30px;
  text-align: center;
  font-weight: 400;
  color: rgb(67, 85, 25);
  // margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.wrapper {
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr;
  margin-top: 3rem;
  // width: 100px;
  // position: relative;
  // z-index: 1;
  //   align-items: center;
}
.form {
  // right: 20px;
  margin: 0 0 60px 40px;
}
.productsWrapper {
  margin: auto;
  // position: fixed;
  // right: 40px;
}
.wrapProduct {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;
}
.product {
  display: flex;
  gap: 2rem;
  // grid-template-columns: 1fr 1fr;
  // grid-template-columns: repeat(2, 1fr);
  align-items: center;
  // display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  // justify-content: space-around;
}

.price {
  grid-column-start: 1;
  grid-column-end: 3;
}

.img {
  max-width: 100%;
  // width: 100%;
  height: auto;
  max-height: 100px;
}
.productDetails {
  // margin-left: 2rem;
  display: flex;
  gap: 1rem;
  // flex-direction: column;
  // justify-content: space-around;
}
.productName {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  // align-self: center;
  padding: 1rem;
}
.productPrice {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 300;
}
.btnArr {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Регулируйте размер кнопки по необходимости */
  height: 40px; /* Регулируйте размер кнопки по необходимости */
  border-radius: 50%;
  background-color: #3498db; /* Цвет фона кнопки */
  color: #fff; /* Цвет текста кнопки */
  cursor: pointer;
  font-size: 20px; /* Регулируйте размер шрифта по необходимости */
  transition: background-color 0.3s;
}
.btnPlus {
  @extend .btn;
  background-color: #198a2c;
  &:hover {
    background-color: #198a2c71; /* Цвет фона кнопки при наведении */
  }
}
.btnMinus {
  @extend .btn;
  background-color: #19198a;
  &:hover {
    background-color: #19198a8b; /* Цвет фона кнопки при наведении */
  }
}
.btnRemove {
  @extend .btn;
  background-color: #850909;
  &:hover {
    background-color: #8509098d; /* Цвет фона кнопки при наведении */
  }
}
.p {
  font-size: 16px;
  font-weight: 300;
}
.span {
  color: red;
}

.spanSmall {
  color: blue;
}

.itogoElemPrice {
  font-size: 20px;
  font-weight: 400;
}

.line {
  position: absolute;
  margin-bottom: 1rem;

  // top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64%;
  height: 0.1px;
  background-color: #474c419e;
}

/* Добавляем стили для мобильных устройств */
@media screen and (max-width: 968px) {
  .wrapper {
    grid-template-columns: 1fr; /* Одна колонка на мобильных устройствах */
  }
  .product {
    flex-direction: column;
  }
  .productDetails {
    flex-direction: column;
  }
  .form {
    margin: 0;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  // margin-top: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.allprice {
  font-size: 18px;
}
.order {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.bybtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: green;
  border: 2px solid orange;
  font-size: 1.5rem;
  color: #ffffff;
  padding: 1rem;
  border-radius: 6px;
  max-width: 220px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background: #1c2344;
    cursor: pointer;
    scale: 1.1;
  }
}

.even_row {
  background-color: #c1d8cf;
}
.odd_row {
  background-color: white;
}
