.wrapper {
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 18px;
  padding: 2rem;
}
.wrapItem {
  border: 1px solid grey;
  padding: 1rem;
  // width: 25%;
  //   display: flex;
}
.img {
  width: 100%;
  height: auto;
}

.products {
  display: flex;
  gap: 1rem;
}
