.wrap {
  position: relative;
  height: 300px;
  @media (max-width: 768px) {
    height: 410px;
  }
  // width: 300px;
}
.wrap_two {
  position: relative;
  height: 300px;
  @media (max-width: 768px) {
    height: 410px;
  }
}

.span {
  color: rgba(184, 61, 61, 0.781);
  font-size: 18px;
}

.block {
  display: none;
  text-align: start;
  font-size: 17px;
  font-weight: 300;
  z-index: 2;
  margin-top: 1rem;
  @media (max-width: 768px) {
    display: block;

    font-size: 12px;
    margin-top: 0.5rem;
  }
}
.price {
  font-weight: 400;
  font-size: 18px;
  padding: 0;
  z-index: 3;
  background: rgb(255, 255, 255);
  margin-top: 1rem;
}
.h2 {
  color: black;
  // margin-top: 1rem;
  font-size: 16px;
  font-weight: 300;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // max-height: 2.5em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  // word-break: break-all; /* Добавляем свойство word-break */
  @media (max-width: 768px) {
    font-size: 12px;
    -webkit-line-clamp: 3;
  }
}
.h2_two {
  color: black;
  font-size: 16px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  word-break: break-word;
  min-height: 2.5em;
  max-width: 100%; /* Убедитесь, что ширина ограничена */
  box-sizing: border-box; /* Учитывает padding и border в ширину */
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 1rem;
    -webkit-line-clamp: 3;
    word-break: break-word;
  }
}

.card {
  display: flex;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "Lora", serif;
  // font-family: "Arsenal", sans-serif;
  font-family: "Arsenal", sans-serif;

  color: black;
  background: rgb(255, 255, 255);
  // border-radius: 7px;
  padding: 20px;
  // box-shadow: 1px -1px black;
  // margin: 1rem;
  flex-direction: column;
  box-shadow: 4px -3px 20px 0px rgba(57, 63, 62, 0.8196078431);
  text-align: center;
  position: absolute;
  flex-wrap: wrap;
  width: 240px;

  // height: 450px;
  height: auto;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0.5rem;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.26);

    // width: 100vw;
  }

  &:hover::before {
    content: "";
    position: absolute;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.347);
    // border-radius: 14px;
    inset: -3% -10%;
    z-index: 2;
    /* стили для :before при hover */
  }

  &:hover {
    .card {
      z-index: 2;
    }
    .img {
      z-index: 3;
    }
    .block {
      display: block;
    }
    .h2 {
      -webkit-line-clamp: unset;
      // -webkit-line-clamp: 4;
    }
    .h2_two {
      -webkit-line-clamp: unset;
    }
    .button {
      display: block;
    }
  }

  // @media (max-width: 768px) {
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     border: 1px solid rgba(0, 0, 0, 0.347);
  //     inset: -5% -10%;
  //     z-index: -1;
  //     /* стили для :before при hover */
  //   }
  // }
}
.img {
  max-width: 100%;
  height: 150px;
  // margin: 0;
  // padding: 0;
}

@media (max-width: 768px) {
  .card {
    display: flex;
    // font-family: "Cormorant Garamond", serif;
    font-family: "Arsenal", sans-serif;

    box-shadow: none;
    border-radius: 7px;
    background: rgba(255, 255, 255, 0.411);
    width: 100%;
    height: 418px;
  }
  .price {
    font-weight: 400;
    font-size: 1rem;
    // padding: 0;
    z-index: 3;
    background: none;
    margin: 0;
  }
  .img {
    // width: 200px;
    height: 110px;
    // object-fit: contain;
    // margin: 0;
    // padding: 0;
  }
}

.button {
  display: none;
  padding: 0.7rem;
  z-index: 2;
  margin-top: 0.5rem;
  // text-align: center;
  // z-index: 30;
  // margin-bottom: 10px;
  // font-family: inherit;
  // font-size: 1rem;
  font-weight: 300;
  border: none;
  cursor: pointer;
  border-radius: 0.2rem;
  background: rgba(38, 41, 39, 0.493);
  color: white;
  @media (max-width: 768px) {
    display: block;
  }
  &:hover {
    background: rgba(212, 179, 29, 0.603);
    transition: 200ms ease-in, color 200ms ease-in;
  }
}
