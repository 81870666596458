.wrapper {
  height: 100vh;
  width: 100vw;
  background-color: none;
  // background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 2;
}
.active {
  @extend .wrapper;
  background-color: rgba(255, 255, 255, 0.6);
  transform: scale(1);
  z-index: 4;
  // background-color: none;
}

.main {
  // margin-top: 20px;
  // display: flex;
  // flex: 1;
  // flex-direction: column;
  // padding: 1rem;
  // background: black;
  background: #a7f1cd;
  padding: 0 1rem;
  // @media (max-width: 768px) {
  //   flex-direction: row;
  // }
}

.top {
  // width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    height: 50px;
    width: 100%;
  }
}

.top__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 2rem;
  gap: 1rem;
  @media (max-width: 768px) {
    margin-top: 0;
    justify-content: start;
  }
}
.svgarray {
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 768px) {
    gap: 1rem;
  }
}

.logoWrap {
  width: 60px;
}

.cityWrapper {
  position: absolute;
  top: 0.7rem;
  left: 1rem;
}

.logo {
  text-decoration: inherit;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  // font-family: "Lobster", cursive;
  // margin-left: 1rem;
  font-size: 2rem;
  color: #ade44e;
  margin-top: 1rem;
  font-weight: 400;
  // font-style: italic;

  // @media (min-width: 400px) {
  //   font-size: 2rem;
  // }
}
.heart {
  font-size: 32px;
  stroke-width: 0px;
  align-self: center;
  @media (max-width: 768px) {
    font-size: 23px;
  }
}

.tel {
  font-size: 26px;
  fill: black;
  align-self: center;
  @media (max-width: 768px) {
    font-size: 19px;
  }
}

.search {
  font-size: 29px;
  @media (max-width: 768px) {
    font-size: 21px;
  }
}

// .cartsvg {
//   stroke-width: 2px;
//   margin: 0.4rem;
//   // height: 100px;
// }

.logop {
  font-size: 36px;
  font-weight: 700;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  color: black;

  text-align: center;
  width: 50%;
  @media (max-width: 768px) {
    font-size: 23px;
  }
}

.logoimg {
  width: 50px;
  height: 50px;
  @media (max-width: 768px) {
    // width: 50px;
    display: none;
    // height: 50px;
  }
  // margin-left: 1rem;
}

.delivery {
  text-align: center;
  border-bottom: 1px solid white;
  text-transform: uppercase;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;
}
.headTop {
  margin: 0;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 0.2rem;
  }
}

@media (max-width: 768px) {
  .delivery {
    // display: none;
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
}

.navbar {
  // display: flex;
  flex-grow: 1;
  // flex-direction: column;
  // flex-direction: row;

  // margin-right: 20px;
}

.seperator {
  width: 80%;
  border: none;
  height: 2px;
  margin-top: 2px;
  background-color: rgb(255, 255, 255);
}

.navigation {
  display: flex;
  // flex: 1;
  // justify-content: flex-end;
  // justify-content: center;
  // margin-right: 450px;
  // margin-top: 1rem;
  // margin-right: 55px;
}

.bottom {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.2rem;
  @media (max-width: 768px) {
    display: none;
  }
}

.email {
  margin-right: 75px;
  color: white;
}

.logotext {
  text-decoration: none;
}
/* Ваши общие стили для ссылок */
// a {
//   text-decoration: none; /* Убираем подчеркивание у ссылок */
//   color: #0073e6; /* Цвет текста для ссылок */
//   font-weight: bold; /* Жирный шрифт для ссылок */
// }

/* Стили для активной ссылки на телефон */
// a[href^="tel:"] {
//   color: #8d1c1c; /* Изменяем цвет текста для ссылки на телефон */
//   /* Другие стили, которые вы хотите применить для активной ссылки */
// }
.cart {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001; /* Если необходимо, чтобы элемент был поверх других элементов */
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
  }
}

//бургер меню:

.burgerButton {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 38px;
  height: 30px;
  padding: 5px;
  // margin-top: 1rem;
}

.burgerIcon {
  width: 100%;
  height: 2px;
  background-color: #161616;
  border-radius: 3px;
  position: relative; // Добавляем position: relative
}

.burgerIcon::before,
.burgerIcon::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #161616;
  border-radius: 3px;
  position: absolute; // Добавляем position: absolute
  left: 0; // Выравниваем левый и правый бары
}

.burgerIcon::before {
  top: -10px; // Размещаем верхний бар выше
}

.burgerIcon::after {
  top: 10px; // Размещаем нижний бар ниже
}
// .gamburger {
//   display: none;
// }

@media (max-width: 1199px) {
  // .logo {
  //   display: none;
  // }
  // .gamburger {
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
  .cityWrapper {
    display: none;
  }
  .top__logo {
    // width: 100%;
    // height: 0;
  }

  // .bottom {
  //   display: none;
  // }

  .burgerButton {
    display: flex;
    // width: 100%;
    height: 100%;
    justify-content: center; /* Центрирование содержимого по горизонтали */
    align-items: center; /* Центрирование содержимого по вертикали */
  }
}

/* Ваши стили для крестика */
.closeIcon {
  width: 30px; /* Задайте ширину крестика */
  height: 30px; /* Задайте высоту крестика */
  background-color: transparent; /* Цвет фона */
  position: relative;
  cursor: pointer;
  top: -10px;
  z-index: 1000;
  margin-top: 1rem;
}
.wrapClose {
  width: 50px;
  height: 50px;
  position: absolute;
}
.closeIcon::before,
.closeIcon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; /* Толщина линий крестика */
  background-color: #fff; /* Цвет линий крестика */
  top: 12%;
  transition: transform 0.2s ease; /* Анимация для крестика */
  transform: translateY(-50%) rotate(-45deg);
}

.closeIcon::before {
  left: 0;
  transform-origin: left center; /* Начальная позиция левой линии */
  transform: translateY(-50%) rotate(45deg);
}

.closeIcon::after {
  right: 10px;
  transform-origin: right center; /* Начальная позиция правой линии */
}

/* Стили для анимации переключения в крестик */
.closeIcon.active::before {
  transform: translateY(-50%) rotate(-45deg); /* Поворот левой линии */
}

.closeIcon.active::after {
  transform: translateY(-50%) rotate(45deg); /* Поворот правой линии */
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  z-index: 100;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  animation: blink 1s infinite;
  transition: transform 0.2s ease;
}

.whatsapp img {
  width: 45px;
  height: 45px;
}

.whatsapp:hover {
  background-color: #1ebe57;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.blinkButton {
  color: white;
  font-size: 40px;
}
