.wrap {
  height: 90vh;
  width: 90vw;
  display: flex;
  gap: 1rem;
  padding: 1rem;
}
.add {
  display: flex;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  height: 100px;
  justify-content: center;
}
.svg {
  width: 3rem;
  stroke: darkblue;

  &:hover {
    scale: 1.3;
    transition: scale 200ms;
  }
}
.btn {
  border: none;
  background: green;
  padding: 1rem;
  color: white;
  font-size: large;
  &:hover {
    background: rgba(0, 128, 0, 0.336);
    cursor: pointer;
  }
}

.btnAdd {
  @extend .btn;
  background: rgb(29, 51, 148);
  font-size: medium;
  &:hover {
    background: rgba(29, 51, 148, 0.466);
  }
}
