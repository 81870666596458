/* SlideDeviceItem.css */

.slide-device-item {
  margin-bottom: 20px;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.options {
  display: flex;
  flex-wrap: wrap;
}

.option {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7a90a3;
  }

  &.selected {
    background-color: #007bff;
    color: #fff;
  }
}
