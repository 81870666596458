.wrapper {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 2fr 5fr;
  align-items: start;
  gap: 2rem;
  padding: 1rem;

  //   margin-bottom: auto;
  //   padding: 2em;
}

// .left {
//     // min-height: 50px;
//     // background: lightblue;
// }
