.promo {
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  margin: 0 1rem;
}
.elementsMenu {
  position: fixed;
  left: 0; /* Показываем меню при активации */
  // top: 80px;
  top: 0;
  margin: 0;
  height: 100vh;
  // padding-top: 2rem;
  // height: 100vh;
  // padding: 0;
  padding: 24% 0 0 0;
  width: auto; /* Автоматический размер для ширины контента */
  background-color: #000000; /* Цвет фона меню */
  transition: left 0.3s ease; /* Анимация появления/скрытия */
  // z-index: 1000;
  list-style: none;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  // justify-content: center;
}

.subMenu {
  // display: none;
  // // opacity: 0; /* Устанавливаем начальное значение непрозрачности */
  // transition: 0.7s ease; /* Добавляем переход для opacity */
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.4s ease-out, max-height 0.3s ease-out;
  opacity: 0;
}

.city {
  // align-self: center;
  margin: 1rem auto;
}
a[href^="tel:"] {
  color: #ffffff; /* Изменяем цвет текста для ссылки на телефон */
  /* Другие стили, которые вы хотите применить для активной ссылки */
}

.info {
  // margin: 1rem auto;
  align-self: center;
  // margin-bottom: 80px;
  margin-top: auto;
  z-index: 1000;
}

// .subMenuActive {
//   display: block;
// }

.menuItemWrapper.active .subMenu {
  // display: block;
  opacity: 1;
  max-height: 1000px;
  // opacity: 1;
}

.menuActive {
  left: -280px; /* Скрываем панель за пределами экрана */
}

.menuSeparator {
  height: 1px; /* Высота разделителя */
  background-color: #ccc; /* Цвет разделителя */
  margin: 10px 0; /* Отступы сверху и снизу разделителя */
}

.menuItem a {
  text-decoration: none;
  font-size: 20px;
  // font-family: "Lora", serif;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  color: #fff;
  // padding: 1rem;
  margin: 1rem;
}

.menuItemSub a {
  text-decoration: none;
  font-size: 20px;
  color: #ffffff;
}

.menuItemSub a:hover {
  color: rgb(160, 160, 160);
}
.menuItem a:hover {
  color: rgb(160, 160, 160);
}

@media (min-width: 1199px) {
  .elementsMenu {
    display: none;
  }
}

.bottom {
  margin-top: 3rem;
}

.email {
  // margin-right: 75px;
  color: white;
}

.phone {
  // margin-right: 65px;
  color: white;
}

.about {
  color: white;
  text-decoration: none;
  // text-align: center;
}
