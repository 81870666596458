.wrapper {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  width: 100%;
  // height: 20vh;
  margin: auto;
  margin-top: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // // grid-auto-rows: 200px;
  grid-template-rows: 20px;
  justify-items: center;
  gap: 1rem;
  row-gap: 40px;
  // background-color: black;
  padding: 1rem;

  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  // font-family: "Lora", serif;
  font-family: "Arsenal", sans-serif;

  // font-weight: 400;s
  padding: 1rem;
  background: #037f2a3e;

  // align-content: end;
  // margin-bottom: 0;
  // position: fixed;
}
.content {
  // min-height: 100vh;

  // margin-top: auto; /* Добавлено */
}

.bybtn {
  color: rgb(65, 13, 13);
  text-decoration: none;

  &:hover {
    color: blackrgb(55, 80, 189);
    cursor: pointer;
  }
}
