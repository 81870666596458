// @font-face {
//   font-family: "AMEDA REGULA";
//   src:
//   //  url("../../../../fonts/ameda-rough-regular-ameda-rough-regular-400.ttf")
//   //     format("truetype"),
//     url("../../../../fonts/Ameda-Regular-BF64e03a9410853.otf")
//     format("opentype");
//   font-weight: normal; /* Нормальная насыщенность шрифта */
//   font-style: normal; /* Обычное начертание шрифта */
// }

.wrapper {
  display: flex;
  position: relative;
  user-select: none;
  overflow: hidden;

  .window {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  .imageContainer {
    // height: 750px;
    width: 100%;
    object-fit: cover;
    // min-height: 100%;
    min-width: 100%;
    position: relative;
  }

  .textBlock {
    position: absolute;
    // font-family: "Lora", serif;
    font-family: "Arsenal", sans-serif;

    // background-color: #a7f1cd96;
    // border-radius: 8px;
    padding: 1rem;
    // display: flex;
    // flex-direction: column;
    // bottom: 2rem;
    left: 50%; /* Сдвигаем левую границу блока к центру контейнера */
    top: 50%;
    font-size: 27px;
    transform: translate(-50%, -50%);
    // background-color: rgba(0, 0, 0, 0.7);
    // padding: 1rem;
    // border-radius: 5px;
    color: white;
    z-index: 1;
    // gap: 1rem;
    max-width: 100%; /* Установите максимальную ширину блока */
    letter-spacing: 0.3em;
    // word-wrap: break-word; /* Разрешить перенос слов */
    // white-space: pre-wrap; /* Сохраняет пробелы и разрывы строк */
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .about {
    position: absolute;
    background-color: #a7f1cd96;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    // font-family: "Cormorant Garamond";
    // font-family: "Lora", serif;
    font-family: "Arsenal", sans-serif;

    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 21px;
    letter-spacing: 0.2em;
    color: white;
    max-width: 100%;
    @media (max-width: 768px) {
      font-size: 18px;
      font-weight: 600;
    }
    // max-width: 500px;
  }

  .image {
    // max-width: 100%;
    // width: 800px;
    // height:1061px;
    max-height: 1061px;
    width: 100%;
    object-fit: cover;
    // min-height: 100%;
    // min-width: 100%;
  }

  .box {
    display: flex;
    height: 100%;
    width: 30px;
    opacity: 0.4;
    position: absolute;
    background-color: black;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  .svg {
    align-items: center;
    fill: rgb(255, 255, 255);
    stroke: rgba(20, 39, 68, 0.904);

    &:hover {
      fill: rgb(147, 147, 197);
    }
  }

  .svg2 {
    @extend .svg;

    &:hover {
      transform: translateX(-30%);
    }
  }
  .btnPay {
    align-self: flex-end;
    text-align: center;
    padding: 8px 16px;
    background-color: #445261;
    width: 200px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-items: flex-end;

    &:hover {
      background-color: #355f12;
    }
  }
}
