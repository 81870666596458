.price-container {
  max-height: 400px; /* Здесь можно установить максимальную высоту контейнера */
  overflow: auto;
}

.price-table {
  border-collapse: collapse;
}

.price-table th,
.price-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.empty-cell {
  width: 50px; /* Ширина пустой ячейки для вертикального текста "Высота" */
}
