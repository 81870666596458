// GateMeasurementForm.module.scss

.gate-form {
  // font-family: Arial, sans-serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  color: white;
  padding: 20px;
  background-color: rgb(52, 49, 90); /* Красивый фон */
  border: 1px solid #ccc; /* Стильный бордер */
  border-radius: 8px; /* Закругленные углы */

  .zagolovok {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .small-input {
    max-width: 120px; // Измените ширину по вашему усмотрению
    // margin-left: 10px;
  }
  .divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }

  div {
    margin-bottom: 10px;

    label {
      display: inline-block;
      width: 150px;
      font-weight: bold;
    }

    input[type="text"]:focus,
    input[type="number"]:focus,
    input[type="tel"]:focus,
    input[type="email"]:focus,
    textarea:focus,
    select:focus {
      outline: none; /* Убираем стандартный контур фокуса */
      border-color: #ff5100; /* Цвет бордера при фокусе */
      box-shadow: 0 0 5px rgba(18, 180, 29, 0.7); /* Тень при фокусе */
    }

    textarea {
      resize: vertical;
      height: 100px;
    }

    select {
      height: 30px;
    }

    input[type="checkbox"] {
      margin-right: 5px;
    }
  }

  .columns {
    display: flex;
    margin-top: 2rem;
  }

  .column {
    width: 50%;
    padding: 0 10px;
  }
}
