.wrapper {
  display: flex;
  flex-direction: column;
}

.price {
  margin-bottom: 20px;
  border-radius: 5px;
  // background: #0b060fe0; /* Черная заливка */
  background: rgba(95, 132, 97, 0.834);
  text-align: center;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    margin: 1rem;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  color: #1c0e55;
  font-weight: 300;
  margin: 1rem;
  gap: 0.5rem;
  justify-content: center;
}

.h1 {
  text-align: center;
  margin-top: 0;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 2rem;
  font-weight: 600;
  border-bottom: 1px solid;
}

.priceText {
  font-style: italic;
  font-weight: 200;
  padding: 1rem;
  font-size: 24px;
  text-align: center;
  // color: #f6af2e; /* Белый цвет текста */
  color: white;
  text-shadow: 0px 0px 5px rgb(223, 232, 124); /* Неоновый эффект */
}

.wrapperI {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

label {
  margin-bottom: 5px;
}

.inputs {
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;

  box-shadow: 0px 0px 8px 0px #5b6dcd;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 7px 1px rgb(97 84 39 / 72%); /* неоновый эффект при фокусе */
  }
}

.btn {
  margin-top: 1rem;
  padding: 10px 20px;
  background-color: #9b3050; /* цвет кнопки */
  color: #fff; /* цвет текста кнопки */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* анимация при наведении */
}

.btn:hover {
  background-color: #27ae60; /* изменение цвета при наведении */
}

.leftdesc {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
  color: #333; // Цвет текста
}

.desc {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc; // Цвет границы
  border-radius: 4px;
  // width: 150px; // Ширина селекта

  &:hover {
    border-color: #555; // Изменение цвета границы при наведении
  }

  &:focus {
    outline: none;
    border-color: #4285f4; // Изменение цвета границы при фокусе
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2); // Тень при фокусе
  }
}
