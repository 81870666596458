// .button {
//   color: white;
//   text-align: center;
//   font-size: x-large;
//   font-weight: 500;
//   text-shadow: 0 0 12px #8d173d;
// }

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.p {
  font-size: 15px;
  margin: 0;
  margin-left: 4px;
  font-weight: 300;
  color: white;
}
.btn {
  font-size: 35px;
  color: white;

  &:hover {
    color: green;
  }
}
.btnminus {
  font-size: 35px;
  color: white;

  &:hover {
    color: red;
  }
}

.plus {
  position: absolute;
  border-radius: 10%;
  font-size: 20px;
  top: -40px;
  left: 0;
  border: none;
  color: white;
  background: rgb(85, 193, 85);
  // margin-left: 4px;
}

.minus {
  position: absolute;
  border-radius: 10%;
  font-size: 20px;
  top: -40px;
  right: 0;
  border: none;
  color: white;
  background: rgb(194, 80, 80);
  // margin-left: 4px;
}
