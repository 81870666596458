.block {
  display: grid;
  grid-template-columns: 2fr 5fr;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  min-height: 10rem;
  gap: 10px;

  //   &_img {
  //     height: 8rem;
  //     width: 8rem;
  //     border-radius: 15%;
  //     object-fit: cover;

  //     &:hover {
  //       scale: 1.3;
  //       z-index: 999;
  //       transition: scale 600ms;
  //     }
  //   }
}
.addbtn {
  background: green;
  border: none;
  font-size: 1rem;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 6px;
  max-width: 120px;
  text-align: center;

  &:hover {
    background: #d30f0f;
    cursor: pointer;
    scale: 1.1;
  }
}
.xls {
  background: rgb(221, 63, 19);

  @extend .addbtn;

  &:hover {
    background: #2a4e3c;
  }
}

.priceButton {
  background: rgb(12, 183, 75);
  @extend .addbtn;
  &:hover {
    background: #2a4e3c;
  }
}
.optionButton {
  background: rgb(210, 192, 22);
  @extend .addbtn;
  &:hover {
    background: #2a4e3c;
  }
}
.gallery {
  width: 100%;
  height: 100%;
  padding: 0.6rem;
}

.content {
  margin-top: auto;
  margin-bottom: auto;
}
.svg_image {
  color: orangered;
  font-size: 35px;
  cursor: pointer;

  &:hover {
    scale: 1.4;
    transition: scale 200ms;
  }
}

.icons {
  position: absolute;
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // align-items: center; /* Выравнивание по вертикали по центру */
  // justify-content: center;
  display: flex;
  gap: 1rem;
  right: 1rem;
  margin: 0.5rem;
}

.svg_edit {
  width: 30px;
  stroke: yellowgreen;
  cursor: pointer;

  &:hover {
    scale: 1.4;
    transition: scale 200ms;
  }
}

.svg_del {
  width: 30px;
  stroke: darkred;
  cursor: pointer;

  &:hover {
    // stroke: rgba(197, 66, 66, 0.507);
    scale: 1.4;
    transition: scale 200ms;
  }
}

.prices {
  display: flex;
  gap: 1rem;
}
