.wrapper {
  display: grid;
  gap: 1rem;
  // align-items: center;
  // justify-items: stretch;
  // place-items: center;
}

.inp {
  width: 8rem;
  max-width: 10rem;
  //calc()
}

.inp_cat {
  // width: 16rem;
  max-width: 8rem;
  height: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  //calc()
}

.svgadd {
  // position: absolute;
  width: 3rem;
  stroke: #ffffff;
  fill: #5b6dcd;
  // top: 5px;

  &:hover {
    scale: 1.3;
    transition: scale 200ms;
    // stroke: rgb(64, 112, 33);
  }
}

.add {
  background: green;
  border: none;
  font-size: 1rem;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 6px;

  &:hover {
    background: #1c2344;
    cursor: pointer;
    scale: 1.1;
  }

  // width: 2rem;
}

.btn {
  cursor: pointer;
  display: block;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid;
  margin-top: 20px;
  border-radius: 10px 100px / 120px;
  border: none;
  background: #5b6dcd;
  color: white;
  position: relative;
  padding: 1rem 6rem 1rem 1rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

  &:hover {
    background: #1c2344;
  }

  .svgedt {
    position: absolute;
    width: 2rem;
    stroke: white;
    right: 35px;
    top: 5px;

    &:hover {
      scale: 1.3;
      transition: scale 200ms;
      stroke: rgb(0, 255, 0);
    }
  }

  .svgconfirm {
    position: absolute;
    width: 2rem;
    stroke: white;
    right: 35px;
    top: 5px;

    &:hover {
      scale: 1.3;
      transition: scale 200ms;
      stroke: rgb(0, 255, 0);
    }
  }

  .svgdel {
    position: absolute;
    width: 2rem;
    stroke: white;
    right: 5px;
    top: 5px;

    &:hover {
      scale: 1.3;
      transition: scale 200ms;
      stroke: red;
    }
  }
}

.listItem {
  // list-style-type: none;
  padding-top: 3rem;
  /* Дополнительные настройки стиля */
}
.subcategoryList {
  list-style-type: none;
  padding-left: 18px;
  padding-top: 15px;
  /* Дополнительные настройки стиля */
}

.categoryWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.expandBtn {
  background-color: #a43c77;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  color: white;
  &:hover {
    scale: 1.2;
    transition: scale 200ms;
    background-color: #d31c1c;
  }
}

.expanded {
  // background-color: #12811b;
  background: #12811b;
}

.city {
  background: #20a754;

  @extend .btn;

  &:hover {
    background: #1c2344;
  }
}
.option {
  background: #c3251a;

  @extend .btn;

  &:hover {
    background: #1c2344;
  }
}
