/* RegionCityTable.module.scss */

.regionCityTable {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .regionList,
  .cityList {
    width: 45%;
    border: 1px solid #ccc;
    padding: 10px;

    h2 {
      margin: 0;
      padding-bottom: 10px;
      font-size: 18px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 5px 0;

        strong {
          margin-right: 5px;
        }
      }
    }
  }
}
