.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    margin-top: 2rem;
}

h2 {
    color: darkolivegreen;
    margin-bottom: 1rem;
    margin-top: 0.3rem;
}

.form {
    border: 1px solid rgba(128, 128, 128, 0.575);
    // width: 600px;
    padding: 1rem 6rem;
}

.inp {
    margin: 1rem 0;
    padding: 0.8rem;
    border: 1px solid rgba(128, 128, 128, 0.575);
    border-radius: 3px;
    width: 300px;

    &:focus-visible {
        outline: 2px solid darkolivegreen;
        border: 0;
    }
}

.btn {
    margin: 1rem 0;
    padding: 0.6rem 1rem;
    border-radius: 4px;
    border: 0;
    background-color: rgba(27, 102, 172, 0.719);
    color: white;
    font-size: 15px;

    &:hover {
        background-color: rgba(27, 102, 172, 0.397);
        cursor: pointer;
    }
}