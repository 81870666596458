.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .text {
        grid-row: 2;
        grid-column: 1 / 3;
        width: 500px;
        padding: 1rem;
        font-size: 1rem;
        font-weight: bold;
    }

    .btn {
        display: flex;
        justify-content: center;
        width: 200px;
        padding: 1rem;
        margin: auto;
        margin-top: 1rem;
        color: white;
        font-weight: 600;
        font-size: 1.2rem;
    }

    .redpre {
        @extend .btn;
        grid-row: 1;
        grid-column: 1;
        background: rgba(255, 0, 0, 0.377);


    }

    .red {
        @extend .btn;
        grid-row: 1;
        grid-column: 1;
        background: red;


        &:hover {
            background: rgba(255, 0, 0, 0.596);
            color: black;
            cursor: pointer;
        }
    }

    .blue {
        @extend .btn;
        grid-row: 1;
        grid-column: 2;
        background: blue;

        &:hover {
            background: rgba(0, 0, 255, 0.596);
            color: black;
            cursor: pointer;
        }
    }
}