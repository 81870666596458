// colors
$color1: #1f8aff;
$color2: #898989;

.li {
  display: flex;
  margin-top: 1rem;
  // gap: 2rem;
}

.tooltip {
  position: absolute;
  left: 42px;
  bottom: 22px;
  width: 180px;
  padding: 4px 12px;
  // margin-left: calc(100%);
  justify-content: center;
  color: #ffffff;
  background-color: #77736ed7;
  border-radius: 2px;
  text-align: center;
  white-space: pre-line;
  font-weight: 300;
  pointer-events: none;
  font-size: medium;
}

.span {
  position: relative;
  font-weight: 350;
  font-size: 22px;
  font-weight: bold;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;
}

.checkbox {
  margin: 20px 0;
  .checkbox_label {
    margin-right: 1rem;
    position: relative;
    display: block;
    height: 20px;
    width: 44px;
    background: $color2;
    border-radius: 100px;
    cursor: pointer;
    transition: all 300ms ease;
    &:after {
      position: absolute;
      left: -2px;
      top: -3px;
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 100px;
      background: lighten($color2, 30%);
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      content: "";
      transition: all 300ms ease;
    }
    &:active:after {
      transform: scale(1.15, 0.85);
    }
  }

  .checkbox_input {
    display: none;
    &:checked ~ label {
      background: $color1;
      &:after {
        left: 20px;
        background: darken($color1, 15%);
      }
    }
    &:disabled ~ label {
      background: lighten($color2, 30%);
      pointer-events: none;
      &:after {
        background: lighten($color2, 20%);
      }
    }
  }
}

.select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-top: 0.5rem;

  &:hover {
    border-color: #1f8aff;
  }
}

.options {
  font-size: 16px;
}

/* Стили для выделенного элемента в списке */
.options:hover,
.options:focus {
  background-color: #1f8aff;
  color: white;
}

/* Стили для активного (выпадающего) элемента */
select:active,
select:focus {
  border-color: #1f8aff;
  box-shadow: 0 0 5px rgba(31, 138, 255, 0.7);
}

.header {
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 1.5rem;
  font-weight: 600;
  // color: #333; /* Цвет текста заголовка */
}

.img {
  width: 100px;
  margin: 10px;
  height: auto;
  float: left;
}
