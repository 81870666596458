.wrapper {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  // width: 100%;
  // display: grid;
  // grid-template-columns: 1fr 7fr;
}

.bigimg {
  // max-width: 725px;
  // width: 700px;
  position: relative;
  width: 100%;
  width: 800px; /* Максимальная ширина изображения */
  // max-width: 800px;
  height: 600px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .bigimg {
    height: 500px;
    height: 100%;
    width: 100%;
    // max-height: 100vh;
    // object-fit: contain;
  }
}
.imgwrapmobile {
  position: relative;
}

.qnt {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  // z-index: 10;
  // background: orangered;
  background: rgba(214, 178, 178, 0.667);
  padding: 1rem;
  color: white;
  border-radius: 10px;
  box-shadow: 1px -1px 4px 0px #00000087;
  @media (max-width: 768px) {
    padding: 0.5rem;
    bottom: 2rem;
  }
}
.height {
  @extend .qnt;
  // right: 3rem;
  bottom: 5rem;
}

.img {
  margin: 0 2rem 1rem 0;
  height: 100px;
  width: auto;
  object-fit: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  // overflow-x: scroll;
}

.nav {
  width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}
