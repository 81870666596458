/* options.module.css */

.optionsContainer {
  display: flex;
  justify-content: space-between;
}

.optionsList {
  flex: 1;
  padding: 16px;
  border-right: 1px solid #ccc;
}

.imgprev {
  width: 200px;
  height: 200px;
}

.addListButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #f0f0f0;
  border-left: 1px solid #ccc;
}

.optionsList p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.optionsList .edit {
  margin-left: 1rem;
  background-color: green;
}
.optionsList .delete {
  margin-left: 1rem;
  background-color: red;
}

.optionsList ul {
  list-style: none;
  padding: 0;
}

.optionsList li {
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
}

.optionsList button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.optionsList button:hover {
  background-color: #0056b3;
}

.optionsList table {
  width: 100%;
}

.optionsList td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.optionsList td:first-child {
  font-weight: bold;
}

.addListButton button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.addListButton button:hover {
  background-color: #1c7430;
}

/* Стилизация четных строк */
tr:nth-child(even) {
  background-color: #b18686; /* Цвет для четных строк */
}

/* Стилизация нечетных строк */
tr:nth-child(odd) {
  background-color: #3d67e39a; /* Цвет для нечетных строк */
}
