.wrapper {
  display: flex;
  flex-direction: column;
}

.bigimg {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: auto;
  height: 600px;
  // max-width: 100%;
  // max-height: 300px;
  object-fit: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.inputs {
  margin-left: 1rem;
}

.move {
  cursor: pointer;
  background: rgb(204, 113, 113);
  border: none;
  border-radius: 2px;
  margin: 2px;
  color: white;
  &:hover {
    scale: 1.3;
    transition: scale 200ms;
  }
}

.imgWrapper {
  position: relative;
  padding: 1rem;
}

.img {
  display: flex;
  justify-content: center;
  // max-width: 100%;
  height: 100px;
  object-fit: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.nav {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.addimg {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 110px;
  // border: 1px solid gray;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.svg {
  width: 3rem;
  stroke: darkblue;

  &:hover {
    scale: 1.3;
    transition: scale 200ms;
  }
}

.svgimage {
  width: 3rem;
  stroke: darkblue;
}

.deleteSvg {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 3rem;
  stroke: red;
  fill: rgba(93, 72, 83, 0.836);
  cursor: pointer;
  z-index: 100;

  &:hover {
    scale: 1.4;
    transition: scale 200ms;
  }
}
