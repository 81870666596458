.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centeredContent {
  position: absolute;
  top: 1px;
  right: 1px;
}
.svgcart {
  position: relative;
  stroke-width: 2px;
  margin: 0.4rem;
  width: 33px;
  // width: 3rem;
  // stroke: rgb(51 19 231 / 0%);
  // fill: rgb(162 228 78 / 84%);
  // font-size: 35px;
  color: #0c0c0c;
  &:hover {
    scale: 1.3;
    transition: scale 200ms;
    // fill: #fffffffc;
    // stroke: rgb(64, 112, 33);
  }
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    width: 20px;

    right: 0;
  }
}

.qnt {
  position: absolute;
  top: -2px;
  right: -3px;

  font-size: 10px;
  z-index: 1;
  color: white;
}
.block {
  position: absolute;
  top: -1px;
  right: -7px;
  width: 13px;
  height: 13px;
  background-color: rgb(150, 7, 7);
  //   border: 1px solid black;
  border-radius: 50%;
}
