.wrapper {
  // margin: 20px;
  // @media (max-width: 768px) {
  //   display: none;
  // }
}

.wrapperCategory {
  display: flex;
  flex-wrap: wrap; /* Разрешаем перенос на новую строку, если не помещается в ширину */
  // gap: 1rem; /* Расстояние между карточками */
  margin: 0.5rem;
  justify-content: space-around;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    text-transform: uppercase;
  }
}

.h4 {
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 1.7rem;
  font-weight: 600;
  text-align: center;
  color: #037f2a;
}
.text {
  font-family: "Arsenal", sans-serif;
  text-indent: 20px;
  // margin: auto;
  padding: 1rem;
  background: #037f2a3e;
  text-align: center;
  // margin: 1rem;
}

.smallText {
  font-size: small;
  margin: 1rem;
}

// .category {
//   width: 200px;
//   height: 250px;
//   background: grey;
//   margin-top: 2rem;
//   margin-left: 2rem;
//   margin-bottom: 2rem;
// }
.category {
  width: 200px; /* Ширина карточки товара */
  height: 250px;
  background-color: #fff; /* Цвет фона */
  border: 1px solid #ccc; /* Граница */
  border-radius: 5px; /* Закругление углов */
  box-shadow: -4px 4px 6px rgba(39, 42, 64, 0.368); /* Тень */
  overflow: hidden; /* Обрезаем содержимое, если оно не помещается */
  transition: transform 0.3s, box-shadow 0.3s; /* Плавные анимации при наведении */
  cursor: pointer;

  &:hover {
    transform: translateY(-4px); /* Поднимаем карточку при наведении */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); /* Усиленная тень */
  }
  &:hover {
    /* Дополнительные стили при наведении на карточку */
    .captionText {
      text-decoration: overline; /* Изменение цвета текста при наведении (замените на нужный цвет) */
    }
  }
  @media (max-width: 768px) {
    width: 100%; /* Ширина карточки товара */
    height: 100%;
    // padding: 1rem;
    border: none;
    border-radius: 0; /* Закругление углов */

    box-shadow: none;
  }
}

.imageWrapper {
  position: relative;
  width: 100%; /* Ширина блока будет равна ширине родительского блока */
  height: 100%; /* Высота блока будет равна высоте родительского блока */
  overflow: hidden; /* Обрезаем изображение, если оно не помещается в блок */
}

.typeimg {
  width: 100%; /* Заполнение всей доступной ширины блока */
  height: 100%; /* Заполнение всей доступной высоты блока */
  object-fit: cover; /* Заполняем блок, сохраняя пропорции и обрезая по необходимости */
  // filter: grayscale(100%);
  // transition: filter 0.3s;
  &:hover {
    filter: grayscale(0%);
  }
  @media (max-width: 768px) {
    filter: grayscale(0%);
  }
}

.caption {
  position: absolute;
  bottom: 20px;
  // font-family: "AMEDA REGULA", sans-serif;
  // font-family: "Cormorant Garamond";
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  left: 0;
  width: 100%;
  background: rgba(11, 17, 13, 0.123); /* Черный полупрозрачный фон */
  text-align: center;
  padding: 2px; /* Поля вокруг текста */
  box-sizing: border-box; /* Учесть padding в ширине блока */
  color: white; /* Цвет текста */
}

.captionText {
  margin: 0; /* Убрать отступы вокруг текста */

  color: rgb(255, 255, 255);
  // font-family: "Lora", serif;
  font-size: 1.5rem;
}

/* Для WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Ширина полосы прокрутки */
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #585858; /* Цвет заполнения ползунка */
  border-radius: 6px; /* Радиус скругления углов ползунка */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f100; /* Цвет фона дорожки */
}

/* Для Firefox */
::-moz-scrollbar {
  width: 12px; /* Ширина полосы прокрутки */
}

::-moz-scrollbar-thumb {
  background-color: #4caf50; /* Цвет заполнения ползунка */
  border-radius: 6px; /* Радиус скругления углов ползунка */
}

::-moz-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона дорожки */
}
