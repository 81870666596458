.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  height: 90vh;
  width: 30vw;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.close {
  font-size: 2rem;
  color: red;
  cursor: pointer;
  position: absolute;
  right: 0;
  // align-self: end;
}

.zakaz {
  // height: 80vh;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.img {
  width: 100px;
  height: 100px;
}

.allprice {
  font-size: 30px;
}
.order {
  display: flex;
  justify-content: center;

  // overflow-y: auto;

  // margin-top: 2rem;
}

.btns {
  font-size: 30px;
}

.plus {
  @extend .btns;
  color: green;
}
.minus {
  @extend .btns;
  color: red;
}
.remove {
  @extend .btns;
  color: black;
}

.bybtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #312f2f;
  box-shadow: 3px -3px 6px 0px #57577b;
  // border: 2px solid orange;
  font-size: 1.5rem;
  color: #ffffff;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-weight: 400;
  padding: 1rem;
  border-radius: 6px;
  max-width: 220px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background: #0c5f18c4;
    cursor: pointer;
    scale: 1.05;
  }
}
