.container {
  max-width: 1920px;
  // padding: 0 1rem;
  margin: 0 auto;
  min-height: 100vh;
  // margin-bottom: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  // background: rgb(99, 95, 95);
  // height: 100%;
}
