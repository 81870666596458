.wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  //   flex-direction: column;
}

@media (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }
}

// .images {
//   margin: 0 2rem 1rem 0;
//   height: 100px;
//   object-fit: cover;
//   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
//   cursor: pointer;
// }
.h4 {
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.images {
  width: 70px;
  height: 70px; /* Set a fixed height to make it a perfect circle */
  border-radius: 50%;
  overflow: hidden; /* Ensure content is clipped inside the circle */
  margin-right: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* добавляем анимацию при изменении тени */
}

.images:hover {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5); /* добавляем тень при наведении на изображение */
}

.images.selected {
  box-shadow: 0 0 8px 6px rgba(122, 148, 254, 0.888); /* изменяем цвет тени для выбранного изображения */
}

.selected {
  box-shadow: 0 0 5px 4px rgba(28, 144, 95, 0.5); /* или другие стили тени для выбранного элемента */
}
