/* worktoxls.module.scss */

.WorkToXLS {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f4f4f4;
}

.WorkToXLS input[type="file"] {
  /* ... Ваши стили для <input> ... */

  /* Скрываем нативную кнопку "Выберите файл" */
  &::-webkit-file-upload-button {
    display: none;
  }

  /* Стили для замененной кнопки "Выберите файл" */
  &::file-selector-button {
    background-color: #34a91d;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 1rem;
  }

  /* Стили для состояния наведения на кнопку */
  &::file-selector-button:hover {
    background-color: #2675b0;
  }
}

.WorkToXLS button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.WorkToXLS button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.WorkToXLS p {
  margin-top: 10px;
  color: #007bff;
  font-weight: bold;
}
