.inp_cat {
  // width: 16rem;
  max-width: 8rem;
  height: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  //calc()
}
.add {
  background: rgb(189, 53, 53);
  border: none;
  font-size: 1rem;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 6px;

  &:hover {
    background: #1c2344;
    cursor: pointer;
    scale: 1.1;
  }

  // width: 2rem;
}
