.wrapper {
  // margin-top: 1rem;
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin: 0 8rem;
  // align-items: start;
  // width: 100%;
  // margin: auto;
  gap: 2rem;
  // display: flex;
  // gap: 1rem;
  // justify-content: center;
  // @media (max-width: 768px) {
  //   flex-direction: column;
  // }
  @media (max-width: 768px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    // width: 50%;
    // grid-template-columns: 1fr;
  }
}
.gallery {
  // width: auto;
  @media (max-width: 768px) {
    // width: 100px;
  }
}

// @media (max-width: 768px) {
//   .wrapper {
//     grid-template-columns: 1fr; /* Одна колонка на мобильной версии */
//   }

//   // .right {
//   //   margin: 0; /* Убираем правый отступ */
//   // }
// }
.price {
  margin-bottom: 20px;
  border-radius: 5px;
  background: #0b060fe0; /* Черная заливка */
  text-align: center;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}
.priceText {
  font-style: italic;
  font-weight: 200;
  font-size: 24px;
  text-align: center;
  color: #f6af2e; /* Белый цвет текста */
  text-shadow: 0px 0px 5px rgb(223, 232, 124); /* Неоновый эффект */
}
.leftdesc {
  font-size: 17px;
  padding-left: 1rem;
  font-weight: 300;
  color: #000000;
}
.description {
  width: 100%;
  border-radius: 2px;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  // margin: 40px;

  p,
  ul {
    padding: 1rem;
    // color: white;
    color: rgb(65, 13, 13);

    background: rgba(3, 127, 42, 0.2431372549);
    font-size: 16px;
    line-height: 1.6;
    margin: 1rem;
  }
}

.h1 {
  text-align: center;
  // font-family: "Cormorant Garamond", serif;
  // font-family: "AMEDA REGULA", sans-serif;
  font-family: "Arsenal", sans-serif;

  color: rgb(2, 47, 36);
  margin: 0 1rem;
  // text-shadow: 1px -1px 0px rgb(0 0 0);
}
.button {
  padding: 0.7rem;
  margin: 10px;
  // font-family: inherit;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 0.2rem;
  background: rgba(95, 132, 97, 0.834);

  &:hover {
    background: rgba(0, 0, 0, 0.358);
    transition: 200ms ease-in, color 200ms ease-in;
  }
}

// @media (max-width: 768px) {
//   .wrapper {
//     grid-template-columns: 1fr; /* Одна колонка на мобильной версии */
//   }

//   .right {
//     margin: 0; /* Убираем правый отступ */
//   }
// }
