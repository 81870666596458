.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  // grid-template-columns: 3fr 2fr;
  width: auto;
  min-width: 100%;
  // grid-auto-rows: minmax(200px, 500px);
  // gap: 1rem;
}

.actions {
  grid-column: 2 / 3;
  justify-self: end;
  display: flex;
  gap: 1rem;
  padding: 0.8rem;
}

.btn {
  border: 0;
  border-radius: 5px;
  background: #4676d7;
  color: #fff;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #1d49aa;
  }
}
