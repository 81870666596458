.modal {
  // height: 100vh;
  // width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: start;
  justify-content: end;
  opacity: 0;
  pointer-events: none;
  transition: 0.6s;

  &.active {
    pointer-events: all;
    opacity: 1;
    z-index: 4;
  }
}

.modalContent {
  border-radius: 5px;
  padding: 1rem;
  min-height: 100vh;
  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  // z-index: 10;
}

.modalContent.active {
  // min-height: 70vh;

  transform: translateX(0);
}
