.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
}

@media (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }
}

// .images {
//   margin: 0 2rem 1rem 0;
//   height: 100px;
//   object-fit: cover;
//   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
//   cursor: pointer;
// }

.images {
  width: 70px;
  height: 100px;
  margin-right: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* добавляем анимацию при изменении тени */
}

.images:hover {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5); /* добавляем тень при наведении на изображение */
}

.images.selected {
  box-shadow: 0 0 5px 2px rgba(35, 35, 162, 0.5); /* изменяем цвет тени для выбранного изображения */
}

.selected {
  box-shadow: 0 0 5px 4px rgba(28, 144, 95, 0.5); /* или другие стили тени для выбранного элемента */
}
